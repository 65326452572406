import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Location from "../components/location"


const BrideListing = () => {
  const data = useStaticQuery(graphql`
    query {
      brideData: allSanityBride(sort: {fields: name, order: ASC}) {
        edges {
          node {
            name
            description
            slug{
              current
            }
            listingImage {
              asset {
                url
              }
            }
          }
        }
      }
      headerImage: file(relativePath: { eq: "homepage_panel_05.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const {brideData, headerImage} = data;
  const brides = brideData.edges

  return (
    <Layout headerImage={headerImage}>
      <SEO title="Bridal Wedding Flower Arrangement Examples" />
      <section className="bg-textured-content py-12 border-b border-gray-400">
        <div className="md:flex container mx-auto">
          <div className="md:w-1/3 pr-12 px-2 md:px-0">
            <h1 className="text-3xl font-serif font-bold text-green-800 mb-2">Gallery</h1>
            <p className="leading-loose mb-8 md:mb-0">Here you will find a small collection of the beautiful weddings we have helped our brides create. If you are ready to get started, please contact us today by phone or <Link to="/contact" className="text-green-800 hover:text-green-500 font-bold">through our contact page.</Link></p>

            <Location subPage/>

          </div>
          <div className="md:w-2/3 md:pl-4 flex flex-wrap">
            {brides.map((b,idx)=>{
              let bride = b.node
              return (
                bride.listingImage ? (
                <div key={idx} className="w-1/2 md:w-1/3 z-20">
                    <Link to={`/brides/${bride.slug.current}`} className="block relative overflow-hidden z-20">
                      <div className="bride-icon m-2 md:m-4 md:shadow-md z-20">
                        <img className="z-20" src={`${bride.listingImage.asset.url}?w=250&h=250&fit=crop`} alt={`${bride.name} Wedding Flower Arrangement Examples`} />
                        <span className="block absolute bride-name z-20">{bride.name} Wedding</span>
                      </div>
                    </Link>
                </div>
                ) : null
              )
            })}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default BrideListing
